<template>
<router-view></router-view>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ogrencikazanim",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
